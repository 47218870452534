<template>
  <v-app>
    <div class="text-center">
      <v-sheet class="d-flex" height="80">
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on" style="margin: 5px">
              <span>{{ typeToLabel[type] }}</span>
              <a-icon type="down-circle" ></a-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="type = 'month'">
              <v-list-item-title>Month</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = 'day'">
              <v-list-item-title>Day</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = 'week'">
              <v-list-item-title>Week</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn icon class="ma-2" @click="$refs.calendar.prev()" style="margin: 5px">
          <a-icon type="left-circle" style="fontSize: 20px"></a-icon>
        </v-btn>
        <v-btn icon class="ma-2" @click="$refs.calendar.next()" style="margin: 5px">
          <a-icon type="right-circle" style="fontSize: 20px"></a-icon>
        </v-btn>
        <v-toolbar-title v-if="$refs.calendar" style="margin-top: 10px">
            {{ $refs.calendar.title }}
        </v-toolbar-title>
        <v-select v-model="weekday" :items="weekdays" dense outlined hide-details label="Weekdays" class="ma-2" style="margin: 5px"></v-select>
      </v-sheet>
      <v-sheet height="800">
        <v-calendar ref="calendar" v-model="focus" :weekdays="weekday" :type="type" :events="events" :event-overlap-mode="mode" :event-overlap-threshold="30" @click:event="showEvent" @click:more="showDay"></v-calendar>
        <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" offset-y>
          <v-card color="grey lighten-4" min-width="350px" flat>
            <v-toolbar :color="selectedEvent.color" dark >
              <v-btn icon @click="showDrawer(selectedEvent.key)">
                <a-icon type="edit" style="fontSize: 30px" @click="showDrawer(selectedEvent.key)"/>
              </v-btn>
              <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <span v-html="selectedEvent.description"></span>
            </v-card-text>
            <v-card-actions>
              <v-btn text color="secondary" @click="selectedOpen = false" >
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
      </div>
  </v-app>
</template>

<script>
export default {
  props: {
    events: {
      type: Array,
      default: () => ({})
    }, 
  },
 data: () => ({
      focus: '',
      type: 'month',
      typeToLabel: {
        month: 'Month', 
        week: 'Week',
        day: 'Day',
        '4day': '4 Days'
      },
      mode: 'stack',
      modes: ['stack', 'column'],
      weekday: [1, 2, 3, 4, 5],
      weekdays: [
        { text: 'Mon - Fri', value: [1, 2, 3, 4, 5] },
        { text: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0] },
        { text: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6] },
        { text: 'Mon, Wed, Fri', value: [1, 3, 5] },
      ],
      value: '',
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
    }),
  methods: {
    showDay({ date }) {
      this.focus = date
      this.type = 'day'
    },
    showEvent ({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        setTimeout(() => {
          this.selectedOpen = true
        }, 10)
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    showDrawer(id){
      this.$emit('clicked', id)
    },
  }
}
</script>

<style scoped>

</style>

