<template>
  <div>
    <a-form-model :model="calendarEvent">
        <a-form-model-item label="Name of Event" ref="title" prop="title">
            <a-input class="invisibox" placeholder="Name of Event" v-model="calendarEvent.name" />
        </a-form-model-item>
        <a-form-model-item label="Date">
            <a-date-picker v-model="calendarEvent.startDate" :disabled-date="disabledStartDate" show-time format="DD-MM-YYYY HH:mm" placeholder="Start" @openChange="handleStartOpenChange"/>
            <span><a-icon type="double-right" style="padding: 5px; fontSize:17px;"></a-icon></span>
            <a-date-picker v-model="calendarEvent.endDate" :disabled-date="disabledEndDate" show-time format="DD-MM-YYYY HH:mm" placeholder="End" :open="endOpen" @openChange="handleEndOpenChange"/>
        </a-form-model-item>
        <a-form-model-item label="Type of Leave">
            <a-select class="invisibox" v-model="calendarEvent.holidayTypeId" :default-value="holidayTypes[0]">
                <a-select-option v-for="holidayType in holidayTypes" v-bind:key="holidayType.id">
                    {{ holidayType.text }}
                </a-select-option>
            </a-select>
        </a-form-model-item>
        <a-form-model-item>
            <a-button type="primary" @click="saveEvent">
                Save
            </a-button>
        </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
export default {
    props: {
        selectedEventId: {
        type: Number
        },
    },
    data() {
        return {
            calendarEvent: {
                eventId: null,
                name: '',
                startDate: null,
                endDate: null,
                holidayTypeId: '',
                description: '',
            },
            holidayTypes: [],
            endOpen: false,
        }
    },
    created () {
        if (this.selectedEventId != null) {
            this.getEvent()
            this.getLists()
        } else {
        this.getLists()
        }
    },
    methods: {
        saveEvent() {
            if(this.calendarEvent.id != null) {
                this.updateCalendarEvent()
                console.log('updated!', this.calendarEvent)
            }
            else {
                this.addCalendarEvent()
            }
        },
        addCalendarEvent() {
            this.$http.post('/holiday/Add_Holiday', this.calendarEvent)
            .then(() => {
                this.$emit('clicked', 'holidayUpdated')
                this.$message.success('Holiday Requested')
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        updateCalendarEvent() {
            this.calendarEvent.eventId = this.selectedEventId
            this.$http.post('/holiday/Update_Holiday', this.calendarEvent)
            .then(() => {
                this.$emit('clicked', 'holidayUpdated')
                this.$message.success('Holiday Updated')
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        getLists(){
            this.$http.get('/lists/Get_List/holidayTypes')
            .then((response) => {
                this.holidayTypes = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        getEvent(){
            let uri  = '/people/Get_Holiday/'
            if (this.selectedEventId != null){
                uri = uri + this.selectedEventId
            }
            this.$http.get(uri)
            .then((response) => {
                this.calendarEvent = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        disabledStartDate(startDate) {
            const endDate = this.calendarEvent.endDate
            if (!startDate || !endDate) {
                return false;
            }
            return startDate.valueOf() > endDate.valueOf();
            },
        disabledEndDate(endDate) {
            const startDate = this.calendarEvent.startDate
            if (!endDate || !startDate) {
                return false;
            }
            return startDate.valueOf() >= endDate.valueOf();
        },
        handleStartOpenChange(open) {
            if (!open) {
                this.endOpen = true;
            }
        },
        handleEndOpenChange(open) {
            this.endOpen = open;
        },
    },
    watch: {
        selectedEventId(newId) {
            this.selectedEventId = newId
            this.getEvent()
        }
    },
}
</script>

<style>

</style>