<template>
    <div class="card card-bordered">
        <div class="card-inner">
            <div class="card-title">
                <h6>Holiday Requests</h6>
            </div>
            <div class="card-body">
                <!-- <v-data-table dense :headers="headers" :items="holidayRequests">
                    <template v-slot:item.userName="{ item }">
                        <avatar style="margin-left:0px;" :src="item.avatarURI" :size="30" :rounded="true" :backgroundColor="item.avatarColor" :username="item.userName"/>
                    </template>
                    <template v-slot:item.startDate="{ item }">
                        {{ $moment(item.startDate).format('DD/MM/YYYY')}}
                    </template> 
                    <template v-slot:item.endDate="{ item }">
                        {{ $moment(item.endDate).format('DD/MM/YYYY')}}
                    </template> 
                    <template v-slot:item.requestStatus="{ item }">
                        <span v-if="item.requestStatus == '0'">Requested </span>
                        <span v-else-if="item.requestStatus == '2'">Denied </span>
                    </template> 
                    <template v-slot:item.actions="{ item }">
                        <a-dropdown :placement="placement">
                            <div class="dropdown">
                            <a class="text-soft dropdown-toggle btn btn-icon btn-trigger">
                                <em class="icon ni ni-more-h"></em></a>
                            </div>
                            <a-menu slot="overlay">
                            <a-menu-item>
                                <a v-on:click="approveHoliday(item)"><i class="bg-success-dim ni ni-edit mr-1"></i> Approve Request</a>
                            </a-menu-item>
                            <a-menu-item>
                                <a v-on:click="denyHoliday(item)"><i class="ni ni-delete mr-1 bg-danger-dim"></i> Deny Request</a>
                            </a-menu-item>
                            </a-menu>
                        </a-dropdown>
                    </template>
                </v-data-table> -->
            </div>
        </div>
        <a-drawer title="Deny Holiday" :width="600" placement="right" :closable="true" @close="closeDenyHoliday()" :visible="showDenyHoliday">
        <denyRequest v-if="selectedRequest" :holidayRequest="selectedRequest"/>
        </a-drawer>
    </div>
</template>

<script>
import denyRequest from '@/components/holiday/requests/denyRequest.vue'

export default {
  components: { denyRequest },
  data() {
    return {
        placement: 'bottomCenter',
        headers: [
            {
                text: 'User',
                align: 'start',
                sortable: false,
                value: 'userName',
            },
            { text: 'Subject', value: 'name' },
            { text: 'Start Date', value: 'startDate' },
            { text: 'End Date', value:'endDate' },
            { text: 'Status', value:'requestStatus'},
            { text: 'Holiday Type', value: 'holidayTypeName'},
            { text: 'Actions', value:'actions'}
        ],
        showApproveRequest: false,
        showDenyHoliday: false,
        holidayRequests: [],
        selectedRequest: null,
        holidayRequest: {},
    }
  },
  created() {
      this.getHolidayRequests()
  },
  methods: {
    getHolidayRequests(){
      this.$http.get('/holiday/Get_HolidayRequests')
      .then((response) => {
          this.holidayRequests = response.data
      })
      .catch(() => {
      this.$message.error('There has been an error')
      })
    },

    approveHoliday(holidayRequest){
      this.selectedRequest = holidayRequest
      this.selectedRequest.eventId = holidayRequest.id
      this.$http.post('/holiday/Approve_Holiday', this.selectedRequest)
      .then(() => {
        this.getHolidayRequests()
        this.$message.success('Holiday Approved')
      })
      .catch(() => {
          this.$message.error('There has been an error')
      })
    },

    denyHoliday(holidayRequest){
      this.selectedRequest = holidayRequest
      this.showDenyHoliday = true
    },
    closeDenyHoliday () {
      this.getHolidayRequests()
      this.showDenyHoliday = false
    }
  },
}
</script>

<style>
.card-body {
    padding: 5px;
    padding-left: 0px;
}
</style>