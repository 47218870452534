<template>
  <div>
      <a-form-model :model="holidayRequest">
        <a-form-model-item label="Name of Event" ref="name" prop="name">
            <a-input v-model="holidayRequest.name" disabled />
        </a-form-model-item>
        <a-form-model-item label="Date">
            <a-date-picker v-model="holidayRequest.startDate" show-time format="YYYY-MM-DD" disabled/>
            <span><a-icon type="double-right" style="padding: 5px; fontSize:17px;"></a-icon></span>
            <a-date-picker v-model="holidayRequest.endDate" show-time format="YYYY-MM-DD" disabled/>
        </a-form-model-item>
        <a-form-model-item label="Type of Leave">
            <a-select v-model="holidayRequest.holidayTypeId" disabled>
                <a-select-option v-for="holidayType in holidayTypes" v-bind:key="holidayType.id">
                    {{ holidayType.text }}
                </a-select-option>
            </a-select>
        </a-form-model-item>
        <a-form-model-item label="Reason" ref="description" prop="description">
            <a-input v-model="holidayRequest.description"/>
        </a-form-model-item>
        <a-form-model-item>
            <a-button type="primary" @click="denyRequest">
                Save
            </a-button>
        </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
export default {
    props: {
        holidayRequest: {
        type: Object,
        default: () => ({})
        }
    },
    data() {
        return {
            holidayTypes: []
        }
    },
    methods: {
        getLists(){
            this.$http.get('/lists/Get_List/holidayTypes')
            .then((response) => {
                this.holidayTypes = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        denyRequest(){
            this.holidayRequest.eventId = this.holidayRequest.id
            this.$http.post('/holiday/Deny_Holiday', this.holidayRequest)
            .then(() => {
                this.$message.success('Holiday Denied')
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
    }
}
</script>

<style>

</style>