<template>
    <div class="card card-bordered">
        <div class="card-inner">
            <h6>Upcoming Holidays</h6>
            <ul class="nk-activity" v-if="upcomingHolidays.length">
                <li class="nk-activity-item" v-for="upcomingHoliday in upcomingHolidays" :key="upcomingHoliday.id">
                    <avatar style="margin:2px;" :src="upcomingHoliday.avatarURI" :size="30" :rounded="true" :backgroundColor="upcomingHoliday.avatarColor" :username="upcomingHoliday.createdByUser"/>
                    <div class="nk-activity-data">
                        <div class="label">{{ upcomingHoliday.name}}</div>
                        <span class="time">{{ $moment(upcomingHoliday.startDate).format('DD/MM/YYYY') }}</span>
                    </div>
                </li>
            </ul>
            <div v-else>
                No holidays upcoming
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            upcomingHolidays: [],
            upcomingHoliday: {},
        }
    },
    created(){
        this.getUpcomingHolidays()
    },
    methods: {
        getUpcomingHolidays () {
            this.$http.get('/holiday/Get_UpcomingHolidays')
            .then((response) => {
            this.upcomingHolidays = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
    }
}
</script>

<style>

</style>