<template>
  <div>
    <div class="row">
      <div class="col-2">
        <button class="btn btn-success" @click="eventSelected()">Request Holiday</button>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-8">
        <div class="card card-bordered">
          <div class="card-inner">
              <calendar :events="events"  @clicked="eventSelected"/>
          </div>
        </div>
      </div>
      <stats />
      <upcoming-holiday />
      <requests />
    </div>
  <a-drawer title="View Calendar Event" :placement="placement" :closable="true" :visible="displayEvent" @close="closeEvent" :width="650">
    <div style="padding:20px;">
      <calendarEvent @clicked="holidayUpdated" :selectedEventId="selectedEventId"/>
    </div>   
    </a-drawer>
  </div>
</template>

<script>
import calendar from '@/components/holiday/calendar.vue'
import calendarEvent from '@/components/holiday/calendarevent.vue'
import stats from '@/components/holiday/dashboard/stats.vue'
import UpcomingHoliday from '@/components/holiday/dashboard/upcomingHoliday.vue'
import requests from '@/components/holiday/dashboard/requests.vue'

export default { 
  components: { calendar, calendarEvent, stats, UpcomingHoliday, requests },

  data() {
    return {
      tab: null,
      holidays: null,
      events: [],
      displayEvent: false,
      placement: 'right',
      selectedEventId: null,
    }
  },
  created() {
    this.getView()
    this.getHolidays()
  },
  methods: {
    getView() {
        this.$http.get('/ViewAccess/holiday')
        .then(() => {
        })
        .catch(() => {
        })
    },
    getHolidays () {
      this.$http.get('/holiday/Get_Holidays')
        .then((response) => {
          this.holidays = response.data
          response.data.forEach(element => {
            this.addHolidays(element)
          })
        })
        .catch(() => {
          this.$message.error('There has been an error')
        })
    },
    addHolidays(holiday) {
      this.events.push({
          key: holiday.id,
          name: holiday.name,
          start: new Date(holiday.startDate),
          end: new Date(holiday.endDate),
          description: holiday.description,
          color: holiday.color,
        })
    },
    holidayUpdated() {
      this.events = []
      this.getView()
    },
    eventSelected(id) {
      this.selectedEventId = id
      this.displayEvent = true
    },
    closeEvent() {
      this.displayEvent = false
    }
  }
}
</script>

<style>

</style>